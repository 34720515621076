import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')

  },
  {
    path: '/klienci',
    name: 'Customers',
    component: () => import(/* webpackChunkName: "Customers List" */ '../views/customers/List.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/projekty',
    name: 'Projects',
    component: () => import(/* webpackChunkName: "Customers List" */ '../views/projects/List.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/produkty/parafie/',
    name: 'Sacrum',
    component: () => import(/* webpackChunkName: "login" */ '../views/products/sacrum/List.vue'),
    meta: {
      requiresAuth: true
    }
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach( (to, from, next) => {
 if(to.matched.some(record => record.meta.requiresAuth) ){
   if( store.getters.isLoggedIn ){
     next()
     return
   }
   next('/login')
 }else{
   next()
 }
})

export default router
