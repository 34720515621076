<template>
  <v-container>
    <v-row class="text-center">

      <v-col class="mb-4">
        <h1 class="display-2 font-weight-bold mb-3">
          Kodefix CRM
        </h1>

        <p class="subheading font-weight-regular">
          Witaj w panelu zarządzania motywami i pluginami Kodefix
        </p>
      </v-col>

      <v-col
        class="mb-5"
        cols="12"
      >
        <h2 class="headline font-weight-bold mb-3">
          Co znajdziesz w panelu?
        </h2>

        <v-row justify="center">
          <span class="subheading mx-3">Możliwość wygenerowania klucza API dla motywu</span>
          <span class="subheading mx-3">Listę wszystkich kluczy</span>
        </v-row>
      </v-col>

      <v-col
        class="mb-5"
        cols="12"
      >
        <h2 class="headline font-weight-bold mb-3">
          Co dalej?
        </h2>

        <v-row justify="center">
          <span class="subheading mx-3">Rozbudowa o kolejne funkcjonalności</span>
        </v-row>
      </v-col>

    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'HelloWorld',

    data: () => ({
      
    }),
  }
</script>
