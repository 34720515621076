<template>
  <v-app>
    <v-app-bar
     app
     color="primary"
     dark
     clipped-left
   >
     <div class="d-flex align-center">
       <v-app-bar-nav-icon color="white" v-if="logged" @click="drawer = !drawer" />
       <v-tab to="/">
         <!-- <v-img
           alt="Vuetify Name"
           class="shrink mt-1 hidden-sm-and-down"
           contain
           min-width="100"
           src="./assets/horizontal-slogan-white.svg"
           width="200"
         /> -->
         Kodefix
       </v-tab>
     </div>
     <v-spacer></v-spacer>
     <v-icon color="white" v-if="logged">mdi-logout</v-icon><v-tab class="white--text" v-if="logged" @click.prevent="logout">Wyloguj się</v-tab>
   </v-app-bar>

   <v-navigation-drawer
     v-if="logged"
     v-model="drawer"
     app
     clipped
     color="secondary"
     width="257"
   >
   <v-divider dark class="my-1"/>
     <div class="d-flex justify-center">
       <v-avatar color="primary">
         <span class="white--text headline">{{ initials }}</span>
       </v-avatar>
     </div>
   <v-divider light class="my-1"/>
     <v-list  dense >
       <v-tab :to="{ name: 'home' }">
         <v-list-item>
           <v-list-item-action>
             <v-icon >mdi-view-dashboard</v-icon>
           </v-list-item-action>
           <v-list-item-content >
             Dashboard
           </v-list-item-content>
         </v-list-item>
       </v-tab>

       <v-tab :to="{ name: 'Customers' }">
         <v-list-item>
           <v-list-item-action>
             <v-icon >mdi-account-multiple</v-icon>
           </v-list-item-action>
           <v-list-item-content >
             Klienci
           </v-list-item-content>
         </v-list-item>
       </v-tab>

       <v-tab :to="{ name: 'Projects' }">
         <v-list-item>
           <v-list-item-action>
             <v-icon >mdi-grease-pencil</v-icon>
           </v-list-item-action>
           <v-list-item-content >
             Projekty
           </v-list-item-content>
         </v-list-item>
       </v-tab>

       <v-tab :to="{ name: 'Sacrum' }">
         <v-list-item>
           <v-list-item-action>
             <v-icon >mdi-church</v-icon>
           </v-list-item-action>
           <v-list-item-content >
             Parafie - Klucze
           </v-list-item-content>
         </v-list-item>
       </v-tab>

     </v-list>
   </v-navigation-drawer>

    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
import store from './store'

import axios from 'axios';
axios.interceptors.request.use(function (config) {
    // Do something before request is sent
    if( store && store.getters && store.getters.isLoggedIn && store.getters.token ){
      config.data.token = store.getters.token;
    }
    return config;
  }, function (error) {
  // Do something with request error
  return Promise.reject(error);
});
axios.interceptors.response.use(function (response) {
    // Do something with response data

    // WARNING: does response.config.url should be checked if contains $store.url??

    if( response.data.token_cdcee0e8da53f3ce4f72dbff593c4ba8 ){
      store.dispatch( 'update_token', response.data.token_cdcee0e8da53f3ce4f72dbff593c4ba8 )
    }
    return response;
  }, function (error) {
    // Do something with response error
  return Promise.reject(error);
});

export default {
  name: 'App',

  methods: {
    logout: function() {
      this.$store.dispatch( 'logout' )
    },
  },
  computed: {
    logged: () => store.getters.isLoggedIn
  },
  data: () => ({
    drawer: null,
    initials: store.getters.initials,

  }),
};
</script>
