import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#2E7D32',
        secondary: '#E8F5E9',
        accent: '#1B5E20',
        error: '#b71c1c',
      },
    },
  },
});
