import Vue from 'vue'
import Vuex from 'vuex'

import axios from 'axios'
import router from '../router'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // url: 'http://localhost:3000/dash',
    url: 'https://platform.kodefix.pl/dash',
    status: localStorage.getItem('token') ? 'success' : null,
    token: localStorage.getItem('token') || '',
    user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : {},
  },
  mutations: {
    auth_success( state, token, user ){
      state.status = "success"
      state.token = token
      state.user = user
      window.location.reload()
    },
    auth_error( state, message ){
      state.status = 'error'
      state.message = message
    },
    logout( state ){
      state.status = 'logged_out';
      state.token = null;
      state.user = {}
    },
    update_token( state, token ){
      state.token = token
    }
  },
  actions: {
    login( {commit, state}, user ){
     return new Promise ( (resolve, reject) => {

       let url = `${ state.url }/users/login`

       axios({
         url: url,
         data: user,
         method: 'POST'
       })
       .then( resp => {
         if( resp.status === 200 ){
           const token = resp.data.token;
           const user = resp.data.user;

           localStorage.setItem( 'token', token )
           localStorage.setItem( 'user', JSON.stringify( user ))

           commit('auth_success', token, user)
           resolve(resp)
         }else{
           commit('auth_error', resp.data.message)
           reject( resp.data.message )
         }
       })
       .catch( err => {
         commit( 'auth_error' )
         localStorage.removeItem('token')
         localStorage.removeItem('user')
         reject(err)
       })
     })
   },
   logout( {commit} ){
     return new Promise( resolve => {
       commit('logout')
       localStorage.removeItem('token')
       localStorage.removeItem('user')
       router.push('/login')
       resolve()
     })
   },
   update_token( {commit}, token ){
     return new Promise( (resolve, reject) => {
       if( token ){
         localStorage.setItem( 'token', token )
         commit('update_token', token)
         resolve(true);
       }else{
         commit('logout');
         localStorage.removeItem('token')
         localStorage.removeItem('user')
         router.push('/login')
         reject(false)
       }
     })
   }
  },
  getters: {
    url: state => state.url,
    token: state => state.token,
    isLoggedIn: state => state.status === 'success',
    initials: state => {
      return (state.user.first+name) ? state.user.first_name.substr(0,1) + state.user.last_name.substr(0,1) : ''
    }
  },
  modules: {
  }
})
